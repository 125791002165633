import { For } from 'solid-js';
import { styled } from 'solid-styled-components';
import typography from '../../style/designSystem';
import { Report } from '../../types/Report';

const Reports = (props: {
  reports : Report[];
}) => (
  <For each={props.reports}>
    {(report, index) => (
      <ReportRow>
        <td />
        <td>
          <ColumnSet>
            <IndexColumn>
              {index() + 1}
            </IndexColumn>
            <SubjectColumn>
              {report.subject}
            </SubjectColumn>
          </ColumnSet>
        </td>
        <td colSpan={2}>
          <ColumnSet>
            <KeywordColumn>
              {report.keyword}
            </KeywordColumn>
            <LinkColumn>
              <a
              href={`https://www.saenggibu.com/paredent/all-care/report?reportId=${report.id}&reportType=${report.reportType}`}
              target="_blank"
              rel="noopener noreferrer"
              >
                리포트 보기
              </a>
            </LinkColumn>
          </ColumnSet>
        </td>
      </ReportRow>
    )}
  </For>
);
export default Reports;

const ReportRow = styled('tr')`
  td:not(:first-of-type) {
    padding-block: 0 !important;
    border: 0 !important;
  }
`;

const ColumnSet = styled('div')`
  width: 100%;
  display: flex;
    div {
      padding: 8px;
      border: 0.5px solid #D9D9D9;
      text-align: center;
      display: flex;
      align-items: center;
      ${typography.body2}
    } 
`;

const IndexColumn = styled('div')`
  width: 40%;
  background-color: #f9f9f9;
  justify-content: center;
`;

const SubjectColumn = styled('div')`
  width: 60%;
  background-color: #fff;
  justify-content: center;
`;

const KeywordColumn = styled('div')`
  width: 60%;
  white-space: nowrap;
  overflow: scroll;
  background-color: #f9f9f9;
  justify-content: start;
`;

const LinkColumn = styled('div')`
  width: 40%;
  background-color: #fff;
  justify-content: center;
  a {
    text-decoration: none;
    color: #2871FA;
  }
`;
