import client from '../service/thanosClient';

export default function useValidateEmail() {
  const validateEmail = async (email: string) => {
    try {
      await client.get(`/access-code?email=${email}`);

      return true;
    } catch (e) {
      return false;
    }
  };

  return validateEmail;
}
