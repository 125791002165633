import { styled } from 'solid-styled-components';
import typography from './designSystem';

const Table = styled('table')`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  td {
    border: 0.5px solid #D9D9D9;
    text-align: center;
    padding-block: 8px;
  }

  thead {
    tr {
      th {
        ${typography.subHeading}
        &:nth-of-type(2) {
          background-color: #F9F9F9;
        }
        &:nth-of-type(1) {
          color: #2219E2;
          width: 20%;
        }
        &:nth-of-type(2) {
          width: 30%;
        }
        &:nth-of-type(3) {
          width: 25%;
        }
        &:nth-of-type(4) {
          width: 25%;
        }
      }
      border-bottom: 2px solid #000;
    }
  }

  tbody {
    tr {
      color: #25213B;
      td {
        ${typography.body1}
        &:nth-of-type(2) {
          background-color: #F9F9F9;
        }
        &:nth-of-type(1) {
          color: #2219E2;
        }
      }
    }
  }
`;

export default Table;
