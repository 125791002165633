import { styled } from 'solid-styled-components';
import { createEffect, createSignal, Show } from 'solid-js';
import { Paredent } from '../../types/Paredent';
import Reports from './Reports';
import { Report } from '../../types/Report';

const AllCareRow = (props: {
  paredent: Paredent;
  isAllReportsOpen: boolean;
  reports: Report[] | undefined;
}) => {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  createEffect(() => {
    setIsOpen(props.isAllReportsOpen);
  });

  return (
    <>
      <Row>
        <td>
          <ToggleButton onClick={toggle} isOpen={isOpen()}/>
          <p>{props.paredent.nickName}</p>
        </td>
        <td>
          <p>{props.paredent.email}</p>
        </td>
        <td>
          <p>{props.paredent.phoneNumber}</p>
        </td>
        <td>
          <p>{props.paredent.usedTicketCount}</p>
        </td>
      </Row>
      <Show when={isOpen() && props.reports}>
        {(reports) => <Reports reports={reports()}/>}
      </Show>
    </>
  );
};

export default AllCareRow;

const Row = styled('tr')`
  td:first-of-type {
    position: relative;
    p {
      padding-left: 20px;
    }
  }
`;

const ToggleButton = styled('button')<{ isOpen : boolean }>`
  position: absolute;
  margin: auto 0;
  left: 8px;
  top: 50%;
  background-color: #fff;
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 10px solid #2219E2;
  border-right: 2px solid transparent;
  cursor: pointer;
  transform: translate(0, -50%) rotate(${({ isOpen }) => (isOpen ? '90deg' : '0')});
  transition: transform ease-out .3s;
`;
