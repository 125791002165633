import {
  Show, createResource, For, createSignal, createEffect,
} from 'solid-js';
import { styled } from 'solid-styled-components';
import fetchParedentsForSchool from '../../service/fetchParedentsForSchool';
import typography from '../../style/designSystem';
import LoadingCell from './LoadingCell';
import Table from '../../style/Table';
import productTypeStore from '../../stores/productTypeStore';
import SaenggibuRow from './SaenggibuRow';
import AllCareRow from './AllCareRow';
import fetchReportsList from '../../service/fetchReportsList';

const TicketDashBoard = () => {
  const productType = () => productTypeStore.productType();

  const isAllCare = () => productTypeStore.productType() === 'allCare';

  const [paredents] = createResource(productType, fetchParedentsForSchool);

  const [reportsList] = createResource(isAllCare, fetchReportsList);

  const [isAllReportsOpen, setIsAllReportsOpen] = createSignal<boolean>(false);

  const openAll = () => setIsAllReportsOpen(true);

  const closeAll = () => setIsAllReportsOpen(false);

  createEffect(() => {
    if (productType() === 'saenggibu') {
      closeAll();
    }
  });

  return (
    <Container>
      <Head>
        <h4>[{productType() === 'allCare' ? '올케어 서비스' : '생기부 열람권'} 이용 현황판]</h4>
        <Show when={productType() === 'allCare'}>
          <ReportsControlButtons>
            <AllControlButton onClick={openAll}>전체 열기</AllControlButton>
            <AllControlButton onClick={closeAll}>전체 닫기</AllControlButton>
          </ReportsControlButtons>
        </Show>
      </Head>
      <Table>
        <thead>
          <tr>
            <th>계정 이름</th>
            <th>카카오 계정 이메일</th>
            <th>전화번호</th>
            <th>{productType() === 'allCare' ? '주문한 보고서' : '사용한 열람권'}</th>
          </tr>
        </thead>
        <tbody>
          <Show when={!paredents.loading && !reportsList.loading} fallback={
            <LoadingCell />
    }>
            {<For each={paredents()}>{
           (paredent) => (
             productType() === 'allCare'
               ? <AllCareRow
                    paredent={paredent}
                    isAllReportsOpen={isAllReportsOpen()}
                    reports={reportsList()
                      ?.find((item) => item.paredentId === paredent.id)?.reports}
                 />
               : <SaenggibuRow paredent={paredent}/>
           )
      }</For>}
          </Show>
        </tbody>
      </Table>
    </Container>
  );
};

export default TicketDashBoard;

const Container = styled('section')`
  width: 100%;
  h4 {
    ${typography.subHeading}
    color: #2219E2;
    margin-bottom: 12px;
  }
`;

const Head = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    ${typography.subHeading}
    color: #2219E2;
    margin-bottom: 12px;
  }
`;

const ReportsControlButtons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const AllControlButton = styled('button')`
  background-color: transparent;
  border: 0;
  color : #4F4F4F;
  cursor: pointer;
  ${typography.body2}
`;
