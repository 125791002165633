import { styled } from 'solid-styled-components';
import SchoolDashBoard from '../components/dashBoardPage/SchoolDashBoard';
import TicketDashBoard from '../components/dashBoardPage/TicketDashBoard';

const DashBoardPage = () => (
  <Container>
    <SchoolDashBoard />
    <TicketDashBoard />
  </Container>
);
export default DashBoardPage;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding-block: 32px;

  >section {
    width: 100%;
  max-width: 896px;
  }
`;
