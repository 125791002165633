import { styled } from 'solid-styled-components';
import { createResource } from 'solid-js';
import typography from '../../style/designSystem';
import fetchSchoolTicketCounts from '../../service/fetchSchoolTicketCounts';
import schoolList from '../../data/schoolList';

const SchoolDashBoard = () => {
  const schoolCode = schoolList[743].code;
  const schoolName = schoolList[743].name;

  const [ticketCounts] = createResource(() => fetchSchoolTicketCounts(schoolCode));

  return (
    <Container>
      <Head>
        <p>{schoolName}</p>
        <p>선생님, 항상 힘내세요!</p>
      </Head>
      <TicketCounts>
        <TicketCount>
          <p>남은 생기부 열람권</p>
          <p><span>{ticketCounts()?.usableOpenTicketCount}</span> 개</p>
        </TicketCount>
        <TicketCount>
          <p>남은 올케어 이용권</p>
          <p><span>{ticketCounts()?.usableAllCareTicketCount}</span> 개</p>
        </TicketCount>
      </TicketCounts>
    </Container>
  );
};

export default SchoolDashBoard;

const Container = styled('section')`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(217, 217, 217, 0.50);
`;

const TicketCounts = styled('div')`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 12px;
`;

const TicketCount = styled('div')`
  width: 200px;
  padding-block: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 10px;
  background-color: #F4F4F4;
  p {
    ${typography.body1}
    &:first-of-type {
      color: #666;
    }
    &:last-of-type {
      span {
        color: #2219E2;
      }
    }
  }
`;

const Head = styled('div')`
  p:first-of-type {
    ${typography.heading3}
    margin-bottom: 8px;
  }
  p:last-of-type {
    ${typography.body1}
  }
`;
