import { Outlet, useNavigate } from '@solidjs/router';

import { Show, createEffect, createResource } from 'solid-js';

import useAuthorize from '../hooks/useAuthorize';

const RouteGuard = () => {
  const navigate = useNavigate();

  const authorize = useAuthorize();

  const [isAuthorized] = createResource<boolean>(authorize);

  createEffect(() => {
    if (isAuthorized.loading) {
      return;
    }

    if (!isAuthorized()) {
      navigate('/login');
    }
  });

  return (
    <Show when={isAuthorized()} fallback={
      <div>
        로딩중
      </div>
    }>
      <Outlet />
    </Show>
  );
};

export default RouteGuard;
