const typography = {
  hero: `
    font-size: 64px;
    font-weight: 700;
    line-height: 1.3;
  `,
  heading1: `
    font-size: 38px;
    font-weight: 700;
    line-height: 1.3;
  `,
  heading2: `
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3;
  `,
  heading3: `
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
  `,
  subHeading: `
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
  `,
  body1: `
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  `,
  body2: `
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  `,
  caption: `
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
  `,
};

export default typography;
