import { styled } from 'solid-styled-components';
import typography from '../style/designSystem';
import productTypeStore from '../stores/productTypeStore';
import schoolList from '../data/schoolList';

const Header = () => {
  const schoolName = schoolList[743].name;

  return (
    <>
      <Container>
        <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/logo_color.png" alt="로고" />
        <Navigation>
          <Item
          onClick={() => productTypeStore.setProductType('allCare')}>
            올케어 현황판
          </Item>
          <Item
          onClick={() => productTypeStore.setProductType('saenggibu')}>
            열람권 현황판
          </Item>
        </Navigation>
        <p>{schoolName}</p>
      </Container>

      <HeaderBlock />
    </>
  );
};

export default Header;

const HeaderBlock = styled('div')`
  width: 100%;
  height: 64px;
`;

const Container = styled('header')`
  width: 100%;
  position: fixed;
  z-index: 1000;
  padding: 16px 22px 16px 32px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 2px solid #C2C5FF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  img {
    width: 180px;
    height: auto;
  }
  
  >p {
    ${typography.subHeading}
    color: #666;
    white-space: nowrap;
  }
`;

const Navigation = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`;

const Item = styled('button')`
  ${typography.subHeading}
  color: #333;
  cursor: pointer;
  border: 0;
  background-color: transparent;
`;
