import { createSignal } from 'solid-js';

const [email, setEmail] = createSignal<string>('');

const [schoolCode, setSchoolCode] = createSignal<string>('');

const userStore = {
  email,

  schoolCode,

  setEmail,

  setSchoolCode,
};

export default userStore;
