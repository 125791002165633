import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { styled } from 'solid-styled-components';
import useLogin from '../hooks/useLogin';
import userStore from '../stores/userStore';
import typography from '../style/designSystem';

const OTPPage = () => {
  const [accessCode, setAccessCode] = createSignal<string>('');

  const navigate = useNavigate();

  const login = useLogin();

  const handleClick = async () => {
    const email = userStore.email();

    if (!email) {
      alert('다시 로그인 해주세요.');
      navigate('/login');
      return;
    }

    if (!accessCode()) {
      alert('코드를 입력해주세요.');
      return;
    }

    try {
      await login(accessCode());

      navigate('/');
    } catch (error) {
      alert('코드를 재확인 후 다시 입력해주세요');
    }
  };

  return (
    <Container>
      <LoginBox>
        <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/logo_color.png" alt="로고" />
        <p>입력하신 이메일로 전달된 인증 코드를 입력해주세요.</p>
        <LoginForm>
          <Input>
            <label for="email">인증 코드</label>
            <input
            id="email"
            type="text"
            value={accessCode()}
            onInput={(e) => {
              setAccessCode(e.currentTarget.value);
            }}
          />
          </Input>
        </LoginForm>
        <LoginButton type='button' onClick={handleClick}>
          로그인
        </LoginButton>
      </LoginBox>
    </Container>
  );
};
export default OTPPage;

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2871FA 0%, #6815CD 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const LoginBox = styled('div')`
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(217, 217, 217, 0.50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  img {
    width: 180px;
    height: auto;
  }

  >p {
    ${typography.subHeading}
  }
`;

const LoginForm = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Input = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
    label {
      width: 120px;
      text-align: center;
      ${typography.subHeading}
    }
    input {
      padding-block: 3px;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      text-align: center;
      ${typography.body1}
      color: #666;
    } 
`;

const LoginButton = styled('button')`
  border: 0;
  padding: 8px 24px;
  border-radius: 10px;
  background-color: #4B52FF;
  color: #FFF;
  cursor: pointer;
  ${typography.subHeading}
`;
