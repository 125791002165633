import client from './thanosClient';

export default async function fetchSchoolTicketCounts(schoolCode : string) {
  try {
    const { data } = await client.get<{
      usableAllCareTicketCount : number;
      usableOpenTicketCount : number;
    }>(`/school/tickets/count?schoolCode=${schoolCode}`);

    const { usableAllCareTicketCount, usableOpenTicketCount } = data;

    return {
      usableAllCareTicketCount,
      usableOpenTicketCount,
    };
  } catch (e) {
    alert('열람권 및 이용권의 갯수를 불러오는데 실패했습니다.\n관리자에게 문의 바랍니다.');

    return {
      usableAllCareTicketCount: 0,
      usableOpenTicketCount: 0,
    };
  }
}
