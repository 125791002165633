import validateAccessToken from '../service/validateAccessToken';

export default function useAuthorize() {
  const authorize = async () => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      return false;
    }

    const isValidToken = await validateAccessToken(accessToken);
    return isValidToken;
  };

  return authorize;
}
