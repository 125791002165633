import { createSignal } from 'solid-js';

export type ProductType = 'allCare' | 'saenggibu';

const [productType, setProductType] = createSignal<ProductType>('allCare');

const productTypeStore = {

  productType,

  setProductType,

};

export default productTypeStore;
