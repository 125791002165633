import { Report } from '../types/Report';
import client from './thanosClient';

export default async function fetchReportsList() {
  try {
    const { data } = await client.get<{
      reports : Report[];
      paredentId : string;
    }[]>('/school/paredents/reports');

    return data;
  } catch (e) {
    alert('보고서 목록을 불러오는데 실패했습니다.\n관리자에게 문의 바랍니다.');

    return [];
  }
}
