import client from './thanosClient';

export default async function validateAccessToken(accessToken: string) {
  try {
    await client.get('/session', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return true;
  } catch (e) {
    return false;
  }
}
