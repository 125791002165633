import { useNavigate } from '@solidjs/router';

import { styled } from 'solid-styled-components';
import useValidateEmail from '../hooks/useValidateEmail';

import userStore from '../stores/userStore';
import typography from '../style/designSystem';
import schoolList from '../data/schoolList';

const LoginPage = () => {
  const navigate = useNavigate();

  const validateEmail = useValidateEmail();

  const handleClick = async () => {
    const email = userStore.email();

    const schoolCode = userStore.schoolCode();

    if (!email || !schoolCode) {
      alert('이메일과 코드를 모두 입력해주세요!');
      return;
    }

    if (schoolCode !== schoolList[743].code) {
      alert('제대로된 학교 코드를 입력해주세요!');
      return;
    }

    const isValidEmail = await validateEmail(email);

    if (!isValidEmail) {
      alert('유효한 이메일이 아닙니다. 다시 입력해주세요.');
      return;
    }

    alert('입력하신 이메일로 인증코드가 전달됩니다.');

    navigate('/otp');
  };

  return (
    <Container>
      <LoginBox>
        <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/logo_color.png" alt="로고" />
        <LoginForm>
          <Input>
            <label for="email">담당자 이메일</label>
            <input
            id="email"
            type="text"
            placeholder='이메일 주소를 입력해주세요'
            value={userStore.email()}
            onInput={(e) => {
              userStore.setEmail(e.currentTarget.value);
            }}
          />
          </Input>
          <Input>
            <label for="schoolCode">학교 코드</label>
            <input
            id="schoolCode"
            type="text"
            placeholder='코드를 입력해주세요'
            value={userStore.schoolCode()}
            onInput={(e) => {
              userStore.setSchoolCode(e.currentTarget.value);
            }}
          />
          </Input>
        </LoginForm>
        <LoginButton type='button' onClick={handleClick}>
          이메일 인증
        </LoginButton>
      </LoginBox>
      <Contact>
        제휴 문의: 070-7174-3777 | contact@elements.im
      </Contact>
    </Container>
  );
};
export default LoginPage;

const Container = styled('div')`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #2871FA 0%, #6815CD 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const LoginBox = styled('div')`
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(217, 217, 217, 0.50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  img {
    width: 180px;
    height: auto;
  }
`;

const LoginForm = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const Input = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
    label {
      width: 120px;
      text-align: center;
      ${typography.subHeading}
    }
    input {
      padding-block: 3px;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      text-align: center;
      ${typography.body1}
      color: #666;
    } 
`;

const LoginButton = styled('button')`
  border: 0;
  padding: 8px 24px;
  border-radius: 10px;
  background-color: #4B52FF;
  color: #FFF;
  cursor: pointer;
  ${typography.subHeading}
`;

const Contact = styled('p')`
  color: #FFF;
  ${typography.body1}
`;
