import { styled } from 'solid-styled-components';
import typography from '../../style/designSystem';

const LoadingCell = () => (
  <Container colSpan={4}>
    로딩중...
  </Container>
);

export default LoadingCell;

const Container = styled('td')`
  width: 100%;
  text-align: center;
  ${typography.heading1}
`;
