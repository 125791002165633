import client from '../service/thanosClient';
import userStore from '../stores/userStore';

export default function useLogin() {
  const login = async (accessCode: string) => {
    const email = userStore.email();

    const { data } = await client.post('/sessions', {
      code: accessCode, email,
    });

    const { accessToken } = data;

    localStorage.setItem('accessToken', accessToken);
  };

  return login;
}
