import { styled } from 'solid-styled-components';
import { Paredent } from '../../types/Paredent';

const SaenggibuRow = (props: {
  paredent: Paredent
}) => (
  <Row>
    <td>
      {props.paredent.nickName}
    </td>
    <td>
      {props.paredent.email}
    </td>
    <td>
      {props.paredent.phoneNumber}
    </td>
    <td>
      {props.paredent.usedTicketCount}
    </td>
  </Row>
);

export default SaenggibuRow;

const Row = styled('tr')``;
