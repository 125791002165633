import { ProductType } from '../stores/productTypeStore';
import { Paredent } from '../types/Paredent';
import client from './thanosClient';

export default async function fetchParedentsForSchool(productType : ProductType) {
  try {
    const { data } = await client.get<Paredent[]>(`/school/paredents?productType=${productType}`);

    return data;
  } catch (e) {
    alert('이용 현황 목록을 불러오는데 실패했습니다.\n관리자에게 문의 바랍니다.');

    return [];
  }
}
