import { Route, Router, Routes } from '@solidjs/router';

import RouteGuard from './page/RouteGuard';
import Layout from './page/Layout';

import DashBoardPage from './page/DashBoardPage';

import LoginPage from './page/LoginPage';
import OTPPage from './page/OTPPage';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" component={RouteGuard}>
          <Route path="/" component={Layout}>
            <Route path="/" component={DashBoardPage} />
          </Route>
        </Route>
        <Route path="/login" component={LoginPage} />
        <Route path="/otp" component={OTPPage} />
      </Routes>
    </Router>
  );
}
